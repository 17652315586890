/*
 * 업무구분: 계좌번호 조회
 * 화 면 명: MSPTS206P
 * 화면설명: 계좌번호 조회
 * 작 성 일: 2023.03.15
 * 작 성 자: 김경태
 */

/*********************************************************************************
 * Vue 화면 templates 설정 영역
 *********************************************************************************/
<template>
  <mo-modal class="fts-modal large" ref="modal" title="계좌번호 조회">
    <div class="wrap-modalcontents">
      <div class="input-top">
        <div class="column w100p gap12">
          <div class="row w100p">
            <div class="left w80p mr0 gap30">
              <div class="wrap-input row">
                <label class="emphasis"> 고객구분 </label>
                <mo-radio v-model="csGb" value="1" class="flex-unset ml0 mr5"> 개인 </mo-radio>
                <!-- <mo-radio v-model="csGb" value="2" class="flex-unset" :disabled="true"> 법인 </mo-radio> -->
              </div>
              <div class="wrap-input row maxw150">
                <label class="emphasis"> 고객명 </label>
                <mo-text-field class="form-input-name" v-model="cnm" clearable placeholder="입력하세요" />
              </div>
              <div class="wrap-input row">
                <label class="emphasis"> 성별 </label>
                <mo-radio v-model="sexTc" value="1" class="flex-unset ml0 mr5"> 남 </mo-radio>
                <mo-radio v-model="sexTc" value="2" class="flex-unset"> 여 </mo-radio>
              </div>
              <div class="wrap-input row" v-if="false">
                <label class="emphasis"> 실명번호 </label>
                <mo-text-field v-model="rcno" clearable placeholder="123456-1234567" />
              </div>
            </div>
            <div class="right w20p"></div>
          </div>
          <div class="row w100p">
            <div class="left w80p gap40 mr0">
              <div class="wrap-input row">
                <label> 상품유형 </label>
                <mo-dropdown :items="gdTypDtlCItems" v-model="gdTypDtlC" placeholder="선택하세요" ref="dropdown1" />
              </div>
              <div class="wrap-input row">
                <label> 비밀번호 </label>
                <m-trans-key-input
                  v-if="isMobile"
                  class="form-input-name" 
                  v-model="pwd"  
                  type="numberMax4" 
                  maxlength="4" 
                  :start="'0'"
                  :end="'-1'"
                  dialog="Y"
                  :isClear="lv_isClear"
                  @masked-txt="fn_SetMaskedTxt">
                  </m-trans-key-input>
                <mo-text-field
                  v-else
                  class="form-input-name" 
                  v-model="pwd"  
                  type="password" 
                  maxlength="4" 
                  clearable 
                  placeholder="입력하세요" />
              </div>
            </div>
            <div class="right w20p"></div>
          </div>
          <div class="row w100p">
            <div class="left w80p mr0">
              <div class="wrap-input row">
                <label class="emphasis"> 고객조회사유 </label>
                <mo-dropdown :items="cssearchRsnCdItems" v-model="cssearchRsnCd" placeholder="선택하세요" ref="dropdown2" />
              </div>
              <div v-if="cssearchRsnView" class="wrap-input row">
                <label class="emphasis">상세사유</label>
                <mo-text-field class="form-input-name" v-model="cssearchRsn" clearable/>
              </div>
            </div>
            <div class="right w20p">
              <div class="wrap-button row">
                <mo-button @click="fn_Init" class="btn-clear"> 초기화 </mo-button>
                <mo-button primary @click="fn_Search" class="btn-inquiry"> 조회 </mo-button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="wrap-table-title row">
        <h2 class="table-title"> 조회내역 </h2>
      </div>
      <div class="wrap-table h-scroll-200">
        <table class="table col-type num-type th-row-gray text-center">
          <thead>
            <tr>
              <th class="w40">선택</th>
              <th>고객명</th>
              <th>생년월일</th>
              <th>상품유형</th>
              <th>상품명</th>
              <th>계좌번호</th>
              <th>관리점</th>
            </tr>
          </thead>
          <tbody>
            <tr  v-for="(data, idx) in tpup95320List" :key="idx">
              <td @click="fn_RowSelected (idx.toString ())"><mo-radio :id="idx.toString ()" v-model='lv_SelectedItem' :value="idx.toString ()" @click="fn_RowSelected (idx.toString ())"/></td>
              <td @click="fn_RowSelected (idx.toString ())">{{data.cnm}}</td>
              <td @click="fn_RowSelected (idx.toString ())">{{data.btdt}}</td>              
              <td @click="fn_RowSelected (idx.toString ())">{{data.accStatC}}</td>
              <!--<td @click="fn_RowSelected (idx.toString ())">{{data.trstTypC}}</td>-->
              <td @click="fn_RowSelected (idx.toString ())">{{data.gdNm}}</td>
              <td @click="fn_RowSelected (idx.toString ())">{{data.tacno + '-' + data.bnkbSeq + '-' + data.accSeq}}</td>
              <td @click="fn_RowSelected (idx.toString ())">{{data.mngBrnm}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_Confirm">확인</mo-button>
      </div>
    </template>
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>
  </mo-modal>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'
export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPTS206P',
  // 현재 화면 ID
  screenId: 'MSPTS206P',
  // 컴포넌트 선언
  components: {
    'ts-alert-popup': TSAlertPopup,
  },
  // 화면명
  props: {
    pPage: String,      // 부모 페이지명
    pGdC: String,       // 신탁상품
    pTrstTypeC: String, // 신탁유형
    pGdTypDtlC: String, // 상품유형
    pUrl: String,
    pProcTc: String,
    pPopupObj: {type:Object, default:null},
  },
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 공통 변수
      isInit: false,          // 초기화 여부
      cnm: '',                //고객명
      pwd: '',                //비밀번호
      csGb: '1',              //고객구분
      sexTc: '1',             //성별
      sexTcView: true,       //개인/법인구분
      rcno: '',               //실명확인번호
      rcnoView: false,        //개인/법인구분
      gdTypDtlC: '',
      cssearchRsnCd: '',      //고객조회사유
      cssearchRsn: '',        //상세사유
      cssearchRsnView: false,
      rowSelect: {},
      popupObj:{},
      pAlertPopupObj: {},
      gdTypDtlCItems: [],
      cssearchRsnCdItems: [],
      lv_SelectedItem: '',
      lv_Params:{},
      lv_ReturnVal: {}, // 리턴값
      lv_ReturnStr: [], // 베이스 화면으로 리턴할 조회조건 텍스트
      isSucs: false, // 리턴값 세팅여부
      disabedType: false,
      custPer: '생년월일',
      eaiCommObj: {
        lv_vm: '',
        trnstId: '',
        auth: '',
        commHeaderVO: {
          eaiId: '',
          fahrTrnId: 'S',
          requestSystemCode: 'F1391',
          reqSrvcNm: '',
          reqMthdNm: '',
          targetSystemCode: '',
          resVONm: '',
          reqVONm: '',
        },
        params: {},
        response: [],
        error_msg: '',
      },
      tpup95320List: [],
      validateRule: [
        (v) => !!v || '미입력된 필수 항목이 있습니다!'
      ],

      isMobile: window.vue.getStore ("deviceState").getters.getIsMobile,
      lv_masked_val: '',          // 마스크 변수 체크
      lv_isClear: false,          // 보안키패드 초기화
      usergrpTc_19: false, // (AS-IS: 영업점텔러체크용 변수) 미선언된 data 추가함, added by anarchi (2023.08.16)

      usergrpTc_19: false,
      brcd: '',
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    modal() {
      return this.$refs.modal
    }
  },
  
  /** watch 정의 영역 */
  watch: {
    //고객조회사유
    cssearchRsnCd: {
      deep:true,
      handler(after, before) {
        if( after === '30' ) {
          this.cssearchRsnView = true
        } else {
          this.cssearchRsnView = false
        }
      },
    },
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    let bInfo = _.cloneDeep(window.vue.getStore("tsStore").getters.getBasInfo.data);
    this.brcd = bInfo.brcd;
    let vUserGrpTc = bInfo.userGrpTc;
    if ( vUserGrpTc != undefined && vUserGrpTc != null && vUserGrpTc != "" ){
      let arrVal = vUserGrpTc.toString().split(",");
      for ( let i = 0 ; i < arrVal.length ; i ++ ){
        if(  arrVal[i] == "19"  ){ //영업점텔러
          this.usergrpTc_19 = true;
          break;
        }
      }
    }

    this.tpup95320List = [];
    //this.fn_ComCode ();
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog ('MSPTS206P');
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_ComCode () {
      if (this.gdTypDtlCItems != null && this.gdTypDtlCItems.length > 0) return;
      this.eaiCommObj.lv_vm = this;
      this.eaiCommObj.trnstId = 'txTSSTS90S1'; // selectTFCP11000A
      this.eaiCommObj.auth = 'S';
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S';
      this.eaiCommObj.params = {
        'TUKEMK':[
          {cId: "5014"},
          {cId: "5180"},
        ],
      };
      // ------------------
      // ※ 원격트랜잭션서비스(PO)호출 API 변경 적용, modified by anarchi (2023.07.12)
      //   TSServiceUtil.invoke → TSServiceUtil.invokeInDirect
      // ------------------
      TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ComCodeCallBack, this.fn_error);
    }, 
    fn_ComCodeCallBack (res) {
      for (let index in res.data.tukemk) {
          let item = res.data.tukemk[index];
          switch (item.cid) {
            case "5014":
              this.gdTypDtlCItems.push ({
                value: item.c,
                text: item.cnm,
              });
              break;
            case "5180":
              this.cssearchRsnCdItems.push ({
                value: item.c,
                text: item.cnm,
              });
              break;
          }
      }
      // ------------------
      // ※ JIRA 이슈[ PMPM22000016-451 ] 조치함, amended by anarchi (2023.07.13)
      //   → (계좌번호 조회 팝업에서) 조회조건 상품유형(콤보) 중 '전체'항목 추가함 (기본 선택)
      // ------------------
      this.gdTypDtlCItems.unshift ({
        value: "",
        text: "전체",
      });
    },

    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val 
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init () {
      this.isInit = false          // 초기화 여부
      this.cnm = ''                //고객명
      this.pwd = ''                //비밀번호
      this.csGb = '1'              //고객구분
      this.sexTc = '1'             //성별
      this.cssearchRsnCd = ''      //고객조회사유
      this.cssearchRsn = ''        //상세사유
      this.gdTypDtlC = ''          //상품유형
      this.rowSelect = {}
      this.lv_SelectedItem = ''
      this.tpup95320List = []
      if(this.pUrl === '2000') {
        this.disabedType = true
      }

      this.lv_isClear = !this.lv_isClear ? true : false

    },
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : open
     ******************************************************************************/
    fn_Open(properties) {
      this.fn_ComCode ();
      this.fn_Init()
      this.modal.open()
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 닫기
     ******************************************************************************/
    fn_Close () {
      this.modal.close()
      this.closeDropdown()
    },
    closeDropdown() {
      this.$refs.dropdown1.close()
      this.$refs.dropdown2.close()
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear () {
      this.cnm = ''                //고객명
      this.pwd = ''                //비밀번호
      this.csGb = '1'              //고객구분
      this.sexTc = '1'             //성별
      this.sexTcView = true        //개인/법인구분
      this.rcno = ''               //실명확인번호
      this.rcnoView = false        //개인/법인구분
      this.cssearchRsnCd = ''      //고객조회사유
      this.cssearchRsn = ''        //상세사유
      this.rowSelect = {}
      this.tpup95320List = []
      this.custPer = '생년월일'
    },
    /******************************************************************************
     * Function명 : fn_csGbChange
     * 설명       : 개인/법인 선택
     ******************************************************************************/
    fn_csGbChange(type) {
      if(type === 1) {
        this.sexTcView = true
        this.rcnoView = false
        this.custPer = '생년월일'
      }else{
        this.sexTcView = false
        this.rcnoView = true
        this.custPer = '실명확인번호'
      }
      this.rowSelect = {}
      this.tpup95320List = []
    },
    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 조회
     ******************************************************************************/
    fn_Search () {
      const lv_Vm = this
      if(this.cnm.length < 2) {
        TSCommUtil.gfn_validate(this, '고객명을 2글자 이상 입력해 주세요.')
        this.errValCnm = true
        //this.$refs['cnm'].focus()
        return
      }
      if(_.isEmpty(this.cssearchRsnCd)) {
        TSCommUtil.gfn_validate(this, '고객조회 사유를 선택해 주세요.')
        this.errValCssearchRsnCd = true
        //this.$refs['cssearchRsnCd'].focus()
        return
      }
      if(this.cssearchRsnCd == '30') {
        if(_.isEmpty(this.cssearchRsn)){
          TSCommUtil.gfn_validate(this, '고객조회 기타 사유를 입력해 주세요.')
          this.errValCssearchRsn = true
          //this.$refs['cssearchRsn'].focus()
          return
        }
      }
      let pParams = {};
      if(lv_Vm.csGb == '1') {
        pParams = {
          sexTc: lv_Vm.sexTc,
          cnm: lv_Vm.cnm,
          pwd: lv_Vm.pwd,
          trstTypC: this.gdTypDtlC,
          csGb: lv_Vm.csGb,
          cssearchRsnCd: lv_Vm.cssearchRsnCd,
          cssearchRsn: lv_Vm.cssearchRsn
        }
      }else{
        pParams = {
          rcno: lv_Vm.rcno,
          cnm: lv_Vm.cnm,
          pwd: lv_Vm.pwd,
          trstTypC: this.gdTypDtlC,
          csGb: lv_Vm.csGb,
          cssearchRsnCd: lv_Vm.cssearchRsnCd,
          cssearchRsn: lv_Vm.cssearchRsn
        }
      }

      this.tpup95320List = [] //초기화

      this.eaiCommObj.lv_vm = this
      // this.eaiCommObj.trnstId = 'selectTPUP95320List1'
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.params = pParams
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600241_S'
      
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_SearchResult)
    },
    /******************************************************************************
     * Function명 : fn_SearchResult
     * 설명       : 조회 콜백
     ******************************************************************************/
    fn_SearchResult (res) {
      //this.tpup95320List = res.data.tpup95350

      if ( res.data.tpup95350.length > 0) {
        for (let j = 0; j < res.data.tpup95350.length; j++) {
          let tmpObj = {}

          tmpObj.accSeq = res.data.tpup95350[j].accSeq
          //tmpObj.accStatC = res.data.tpup95350[j].accStatC
          //상품유형 조회가 안되어 accStatC 값으로 대체
          let item = res.data.tpup95350[j].trstTypC;
          switch (item) {
            case "001":
              tmpObj.accStatC = '특정금전신탁'
              break;
            case "002":
              tmpObj.accStatC = '유가증권신탁'
              break;
            case "003":
              tmpObj.accStatC = '부동산신탁'
              break;
            case "004":
              tmpObj.accStatC = '금전채권신탁'
              break;
            case "005":
              tmpObj.accStatC = '종합재산신탁'
              break;
            case "006":
              tmpObj.accStatC = '유언대용신탁'
              break;
            case "007":
              tmpObj.accStatC = '치매전용신탁'
              break;
            case "008":
              tmpObj.accStatC = '유언대용신착(금전)'
              break;
            case "009":
              tmpObj.accStatC = '유언대용신탁(유가증권)'
              break;
            case "010":
              tmpObj.accStatC = '유언대용신탁(부동산)'
              break;
          }
          tmpObj.bnkbPswd = res.data.tpup95350[j].bnkbPswd
          tmpObj.bnkbSeq = res.data.tpup95350[j].bnkbSeq
          tmpObj.brcd = res.data.tpup95350[j].brcd
          tmpObj.brigAccYn = res.data.tpup95350[j].brigAccYn
          tmpObj.btdt = res.data.tpup95350[j].btdt
          tmpObj.cnm = res.data.tpup95350[j].cnm
          tmpObj.csGb = res.data.tpup95350[j].csGb
          tmpObj.csId = res.data.tpup95350[j].csId
          tmpObj.gdC = res.data.tpup95350[j].gdC
          tmpObj.gdNm = res.data.tpup95350[j].gdNm
          tmpObj.mngBrcd = res.data.tpup95350[j].mngBrcd
          tmpObj.mngBrnm = res.data.tpup95350[j].mngBrnm
          tmpObj.pageRowCnt = res.data.tpup95350[j].pageRowCnt
          tmpObj.pwByCustYN = res.data.tpup95350[j].pwByCustYN
          tmpObj.rcno = res.data.tpup95350[j].rcno
          tmpObj.rcnoKindTc = res.data.tpup95350[j].rcnoKindTc
          tmpObj.sexTc = res.data.tpup95350[j].sexTc
          tmpObj.stndKeyId = res.data.tpup95350[j].stndKeyId
          tmpObj.tacno = res.data.tpup95350[j].tacno
          tmpObj.trstTypC = res.data.tpup95350[j].trstTypC

          this.tpup95320List.push(tmpObj)
        }
        
      }


    },
    /******************************************************************************
     * Function명 : fn_Confirm
     * 설명       : 확인
     ******************************************************************************/
    fn_Confirm () {
      if(_.isEmpty(this.rowSelect)) {
        this.fn_Close ()
      }
      else {
        //this.fn_ConfirmResult('1','1')
        if(this.tpup95320List.length > 0) {
          //let userMngBrcd = nexacro.getApplication().gds_userInfo.getColumn(0, "brcd");
          let userMngBrcd = this.brcd
          let rstMngBrcd = this.rowSelect.mngBrcd
          let pwByCustYN = this.rowSelect.pwByCustYN
          if (!_.isEmpty(rstMngBrcd)) {
            if ((userMngBrcd !== "916" && ! this.usergrpTc_19) && userMngBrcd !== rstMngBrcd) {
              if (pwByCustYN != "Y") {
                TSCommUtil.gfn_validate(this, '해당 부점의 고객이 아닙니다.')
                this.rstMngBrcd = true
                return
              }
            }
          }
/*
          this.objRtnArr = new Array(18); //팝업창 close시 전달할 값을 위한 배열 선언

          this.objRtnArr[0] = this.ds_list.getColumn(this.ds_list.rowposition, "csId"); //고객ID
          this.objRtnArr[1] = this.ds_list.getColumn(this.ds_list.rowposition, "cno"); //고객번호
          this.objRtnArr[2] = this.ds_list.getColumn(this.ds_list.rowposition, "cnm"); //고객명
          this.objRtnArr[3] = this.ds_list.getColumn(this.ds_list.rowposition, "rcnoKindTc"); //실명확인번호종류구분코드
          this.objRtnArr[4] = this.ds_list.getColumn(this.ds_list.rowposition, "rcno"); //실명확인번호
          this.objRtnArr[5] = this.ds_list.getColumn(this.ds_list.rowposition, "cono"); //법인번호
          this.objRtnArr[6] = this.ds_list.getColumn(this.ds_list.rowposition, "genTaxtTc"); //일반과세구분코드
          this.objRtnArr[7] = this.ds_list.getColumn(this.ds_list.rowposition, "persnBusiMnBsno"); //개인사업자사업자등록번호
          this.objRtnArr[8] = this.ds_list.getColumn(this.ds_list.rowposition, "corpYn"); //법인여부
          this.objRtnArr[9] = this.ds_list.getColumn(this.ds_list.rowposition, "csIvtdRatingC"); //고객투자성향등급코드
          this.objRtnArr[10] = this.ds_list.getColumn(this.ds_list.rowposition, "procDt"); //설문 처리일자
          this.objRtnArr[11] = this.ds_list.getColumn(this.ds_list.rowposition, "valdTrmYys"); //유효기간년수
          this.objRtnArr[12] = this.ds_list.getColumn(this.ds_list.rowposition, "ques10"); //파생상품투자기간
          this.objRtnArr[13] = this.ds_list.getColumn(this.ds_list.rowposition, "tacno"); //종합계좌번호
          this.objRtnArr[14] = this.ds_list.getColumn(this.ds_list.rowposition, "age"); //보험나이
          this.objRtnArr[15] = this.ds_list.getColumn(this.ds_list.rowposition, "tacnoBnkbSeq"); //종합계좌번호 + 통장일련번호
          this.objRtnArr[16] = this.ds_list.getColumn(this.ds_list.rowposition, "regDate"); //고객가입일자(고객기본.등록일자)
          this.objRtnArr[17] = this.ds_list.getColumn(this.ds_list.rowposition, "tassIssncAccYn"); //TASS발급계좌여부(구고객 : N / 신고객:Y)
          */
          let pParams = {
            tacno: this.rowSelect.tacno,
            csId: this.rowSelect.csId,
            trDate: this.rowSelect.trDate,
            cssearchRsnCd: this.cssearchRsnCd,
            cssearchRsn: this.cssearchRsn,
            linkSrnAddr: 'MSPTS200M',
            linkSrnNm: '계약 신규',
            custInqrPathCd: '1610'
          }
          
          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.trnstId = 'insertTPUP95320Log'
          this.eaiCommObj.auth = 'I'
          this.eaiCommObj.params = pParams
          this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10600079_S'
          // ------------------
          // ※ 원격트랜잭션서비스(PO)호출 API 변경 적용, modified by anarchi (2023.07.12)
          //   TSServiceUtil.invoke → TSServiceUtil.invokeInDirect
          // ------------------
          //TSServiceUtil.invoke(this.eaiCommObj, this.fn_ConfirmResult)
          TSServiceUtil.invokeInDirect (this.eaiCommObj, this.fn_ConfirmResult)
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_ConfirmResult
     * 설명       : 확인 콜백
     ******************************************************************************/
    fn_ConfirmResult (pResultData, pErrorCode) {
      this.$emit('ts-popup206-callback', this.rowSelect)
      this.fn_Close ()
    },
    /******************************************************************************
     * Function명 : fn_RowSelected
     * 설명       : 조회내역 선택
     ******************************************************************************/
    fn_RowSelected (idx) {
      this.lv_SelectedItem = idx
      this.lv_SearchParam = {}
      let findIdx = this.tpup95320List.findIndex(obj => obj.chnlCustId === this.lv_SelectedItem)
      if (findIdx !== -1) {
        Object.assign(this.lv_SelectedItem, this.tpup95320List[findIdx])
      }
      this.rowSelect = this.tpup95320List[idx]
    }
  }
}
</script>
